@import '../../styles/functions/rem-calc';
@import '../../styles/vars-colors';
@import '../../styles/vars-box-shadows';
@import '../../styles/vars-border-radii';

.sw-card-job {
  background-color: $color-alabaster-default;
  border-radius: $border-radius-medium;
  border: solid $color-white;
  box-shadow: $box-shadow-medium;
  padding: rem-calc(16);

  .h6 {
    margin-bottom: rem-calc(4);
  }
}

@import "../../styles/functions/rem-calc";
@import "../../styles/mixins/list-unstyled";
@import "../../styles/vars-border-radii";
@import "../../styles/vars-colors";
@import "../../styles/vars-media-queries";

.sw-section-reifegrad {
  .introduction {
    margin-bottom: rem-calc(16);
  }

  ol.segment-list {
    @include mixin-list-unstyled;

    list-style: none;
    counter-reset: self-check-counter;

    li {
      counter-increment: self-check-counter;
      font-size: rem-calc(24);
      margin-left: rem-calc(24);
      opacity: .7;
      padding-left: rem-calc(4);
    }

    li::before {
      content: counter(self-check-counter) ". ";
      color: $color-black-default;
      display: inline-block;
      font-weight: bold;
      line-height: rem-calc(32);
      margin-left: rem-calc(-28);
      position: absolute;
      width: 1rem;
    }

    li.active {
      opacity: 1;
    }
  }

  .evaluation-wrapper {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-gap: rem-calc(16);
  }

  .evaluation {
    border: solid 2px $color-black-default;
    border-radius: $border-radius-small;
    padding: rem-calc(16) rem-calc(24);

    display: grid;
    grid-column-gap: rem-calc(16);
    grid-gap: rem-calc(16);
    grid-template-columns: minmax(0, 1fr);

    @media #{$mq-min768} {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .segment {
      display: flex;
      flex-direction: column;
      height: 100%;
      margin-bottom: rem-calc(32);

      > div {
        flex: none;
        text-align: center;
      }

      .title {
        font-weight: 600;
        padding-bottom: rem-calc(16);
      }

      .fail {
        color: $color-red-default;
      }

      @media #{$mq-min768} {
        margin-bottom: 0;

        .title {
          flex: 1 0 auto;
          padding-bottom: 0;
        }
      }
    }
  }

  .evaluation-questions {
    border: solid 2px $color-black-default;
    border-radius: $border-radius-small;
    padding: rem-calc(16) rem-calc(24);

    .segment {
      border-bottom: solid 1px $color-white;
      margin-bottom: rem-calc(16);
      padding-bottom: rem-calc(16);

      &:last-child{
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      .title {
        display: flex;
        font-weight: 600;

        > div:first-child {
          margin-right: rem-calc(4);
        }
      }

      .question {
        display: block;

        @media #{$mq-min480} {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
        }

        > div {
          flex: 1 1 auto;
          padding: rem-calc(8) rem-calc(16);
        }

        > div:last-child {
          flex: 0 0 rem-calc(128);
          text-align: right;
          font-weight: 600;

          @media #{$mq-min480} {
            text-align: center;
          }
        }
      }
    }
  }

  &-alabaster {
    background-color: $color-alabaster-default;

    .evaluation,
    .evaluation-questions {
      background-color: $color-alabaster-light;
    }
  }

  &-pacific-blue {
    background-color: $color-pacific-blue-default;
    color: $color-white;

    ol.segment-list {
      li::before {
        color: $color-white;
      }
    }

    .evaluation,
    .evaluation-questions {
      background-color: $color-alabaster-default;
      border: solid 2px $color-white;
      color: $color-black-default;
    }
  }

  .sw-button-group--fullwidth {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;

    .btn {
      width: auto;
    }
  }
}

@import "./vars-colors";

a {
  color: inherit;
  text-decoration: none;
}

.sw-link {
  border-bottom: 2px solid $color-pacific-blue-default;
  font-weight: 600;
}

@import '../../styles/functions/rem-calc';
@import '../../styles/vars-colors';
@import '../../styles/vars-border-radii';

.sw-self-check-question {
  border: solid 2px $color-black-default;
  border-radius: $border-radius-small;
  margin-bottom: rem-calc(16);
  padding: rem-calc(16) rem-calc(24);

  .question {
    margin-bottom: rem-calc(16);
  }

  .radio-button-group {
    display: flex;
    column-gap: rem-calc(16);
  }

  &-alabaster {
    background-color: $color-alabaster-light;
  }

  &-pacific-blue {
    background-color: $color-alabaster-default;
    border: solid 2px $color-white;
    color: $color-black-default;
  }

  &.error {
    border: solid 2px $color-red-default;
  }
}

@import "../../styles/functions/rem-calc";
@import "../../styles/vars-media-queries";

.sw-section-job-list {
  .target-persons-filter-links {
    display: none;
    white-space: nowrap;

    > button {
      font-size: rem-calc(14);
      margin: 0 rem-calc(16);
      text-decoration: underline;
    }

    @media #{$mq-min480} {
      display: flex;
      justify-content: flex-end;
    }
  }
}

@import "./functions/rem-calc";
@import "./vars-border-radii";
@import "./vars-colors";

/* Markdown */

.strapi-markdown {
  h1, h2, h3, h4, h5 {
    color: $color-pacific-blue-dark;
  }

  h2, h3, h4, h5, h6 {
    padding-top: 2rem;
  }

  h1 {
    font-size: rem-calc(26);
  }

  h2 {
    font-size: rem-calc(24);
  }

  h3 {
    font-size: rem-calc(22);
  }

  h4 {
    font-size: rem-calc(20);
  }

  h5 {
    font-size: rem-calc(18);
  }

  h6 {
    font-size: rem-calc(16);
  }

  a {
    border-bottom: 2px solid $color-pacific-blue-default;
    color: inherit;
    font-weight: 600;
    text-decoration: none;
  }

  code {
    display: inline-block;
    font-family: inherit;
    padding: rem-calc(16);
    background-color: $color-alabaster-light;
    border-radius: $border-radius-small;
  }

  // any last element
  & > :last-child {
    margin-bottom: 0;
  }

  hr {
    border: none;
    padding-bottom: 2rem;
  }

  ul, ol {
    li {
      margin-bottom: rem-calc(12);
    }
  }
}

@import './functions/rem-calc';
@import './vars-border-radii';
@import './vars-colors';

// checkbox & radio

/* The container */
.checkbox {
  display: block;
  padding-left: rem-calc(48);
  position: relative;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  background-color: $color-white;
  border: solid $color-pacific-blue-default 2px;
  border-radius: $border-radius-small;
  height: rem-calc(28);
  left: 0;
  position: absolute;
  top: rem-calc(4);
  width: rem-calc(28);
}

.checkmark.error {
  border: solid $color-red-default 2px;
}

/* On mouse-over, add a grey background color */
//.checkbox:hover input ~ .checkmark {
//  background-color: $color-grey-1;
//}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked ~ .checkmark {
  background-color: $color-pacific-blue-default;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
  left: rem-calc(8);
  top: rem-calc(2);
  width: rem-calc(8);
  height: rem-calc(16);
  border: solid white;
  border-width: 0 rem-calc(4) rem-calc(4) 0;
  transform: rotate(45deg);
}


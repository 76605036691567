@import "../../styles/functions/rem-calc";
@import "../../styles/vars-border-radii";
@import "../../styles/vars-box-shadows";
@import "../../styles/vars-colors";

@import "~react-image-gallery/styles/scss/image-gallery.scss";

//.image-gallery {
//  height: rem-calc(500);
//  width: auto;
//}

.image-gallery-slide img {
  height: auto;
  max-height: rem-calc(500);
  object-fit: cover;
  object-position: center center;
  overflow: hidden;
  width: 100%;
}

.sw-section-image-gallery {
  .image-gallery-square {
    position: relative;
    width: 100%;

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    .slide {
      align-items: center;
      background-color: rgba(255,255,255,1);
      border-radius: $border-radius-small;
      bottom: 0;
      box-shadow: $box-shadow-small;
      display: flex;
      justify-content: center;
      left: 0;
      overflow: hidden;
      padding: rem-calc(24);
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;

      &.landscape {
        .sw-image {
          width: 100%;
        }
      }

      &.portrait {
        .sw-image {
          width: 75%;
        }
      }
    }
  }

  &-alabaster {
    background-color: $color-alabaster-default;
  }

  &-pacific-blue {
    background-color: $color-pacific-blue-default;
    color: $color-white;
  }

  &-techbar {
    background-image: $color-techbar-section-linear-gradient-1;
    background-position: center center;
    color: $color-white;
  }
}

[class^="sw-section-image-gallery-portal"] {
  align-items: center;
  background-color: rgba(0, 0, 0, .9);
  bottom: 0;
  color: $color-white;
  display: flex;
  justify-content: center;
  left: 0;
  padding: rem-calc(32);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 200;

  .closer {
    position: absolute;
    right: rem-calc(16);
    top: rem-calc(16);
  }
}

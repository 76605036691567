@import "~normalize.css";

@import "fonts";
@import "typography";

@import "colors";
@import "buttons";
@import "containers";
@import "grids";
@import "icons";
@import "links";
@import "lists";
@import "markdown";
@import "sections";
@import "utils";

@import "print";

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  height: 100vh;

  body {
    color: $color-gunmetal-default;
    font-family: "Raleway Regular", sans-serif;
    font-size: 16px;
    font-stretch: 100%;
    font-style: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-weight: 400;
    line-height: 1.5;
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

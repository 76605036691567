.backgroundGreen {
  background-color: green;
}


.maturity-svg {
  background: #d0ffff;
  aspect-ratio: 1;
  font-family: "Raleway Regular",sans-serif;

  line.dashed {
    stroke: #ffffff88;
    stroke-dasharray: 5; /* dashes and gaps are both 5 units long */
  }

  .polygon {
    fill: none;
    stroke-linejoin: round;
    stroke-width: 1.5;
  }

  .polygon.gray {
    stroke: #00008b33;
  }

  .polygon.scale5 {
    stroke: hsl(129, 49%, 50%);
  }

  .polygon.scale4 {
    stroke: hsl(82, 68%, 56%);
  }

  .polygon.scale3 {
    stroke: hsl(58, 78%, 56%);
  }

  .polygon.scale2 {
    stroke: hsl(45, 89%, 55%);
  }

  .polygon.scale1 {
    stroke: hsl(21, 84%, 58%);
  }

  text > .scale5 {
    fill: hsl(129, 49%, 37%);
  }
  text > .scale4 {
    fill: hsl(82, 68%, 42%);
  }
  text > .scale3 {
    fill: hsl(58, 78%, 42%);
  }
  text > .scale2 {
    fill: hsl(45, 89%, 42%);
  }
  text > .scale1 {
    fill: hsl(21, 84%, 42%);
  }
  text > .scale0 {
    fill: #ddd;
  }

  .polygon.answers {
    stroke: #116f7b;
    stroke-width: 2.5;
    filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.35));
    fill:rgba(255, 140, 0, 0.05)
  }

  .background {
    stroke-width: .5;
    stroke: lightblue;
  }

  .background.organisation {
    fill:rgb(217, 217, 205)
  }

  .background.technology {
    fill:rgb(200, 200, 180)
  }

  .background.data {
    fill:rgb(175, 175, 150)
  }

  .background.process {
    fill:rgb(150, 150, 130)
  }
}

@media (min-width: 1024px) {
  .maturity-svg {
    width: 75%;
  }
}
@import '../../styles/functions/rem-calc';

.sw-carousel-slide-generic {
  .h6 {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .details {
    display: flex;
    flex-direction: column;
    gap: rem-calc(8);
    padding: rem-calc(16) rem-calc(16) 0 rem-calc(16);
  }

  .content {
    padding: 0 rem-calc(16) rem-calc(16) rem-calc(16);
    white-space: pre-wrap;
  }
}

@import '../../styles/functions/rem-calc';
@import '../../styles/vars-colors';

.sw-brand {
  display: inline-flex;
  flex-direction: row;

  > div {
    display: flex;
    align-items: stretch;
  }
}

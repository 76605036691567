@import "../../styles/functions/rem-calc";
@import "../../styles/vars-border-radii";
@import "../../styles/vars-box-shadows";
@import "../../styles/vars-colors";
@import "../../styles/vars-media-queries";

@import "../../styles/form-checkbox-sib";

.sw-form-send-in-blue {
  .sib-form {
    
    #error-message,
    #success-message {
      padding: rem-calc(8) 0;

      .sib-form-message-panel__text {
        display: flex;
        align-items: center;

        svg {
          height: rem-calc(24);
          margin-right: rem-calc(8);
          width: rem-calc(24);
        }
      }

      display: none;
    }

  }
  #sib-form {
    box-shadow: $box-shadow-medium;
    border-radius: $border-radius-small;
    border: solid $color-pacific-blue-default 2px;
    overflow: hidden;
    padding: rem-calc(24);
    background-color: $color-white;

    .input--hidden {
      display: none !important;
    }

    textarea,
    input[type="text"] {
      border-radius: $border-radius-small;
      border: solid $color-grey-1 2px;
      display: block;
      font-size: rem-calc(14);
      outline: none;
      padding: rem-calc(8);
      transition: 0.5s;
      width: 100%;

      @media #{$mq-min1280} {
        width: 75%;
      }

      &:focus {
        border: solid $color-pacific-blue-default 2px;
      }

      &.error {
        border: solid $color-red-light 2px;
      }
    }

    input[type="checkbox"] {
      margin-right: rem-calc(16);
      height: rem-calc(24);
      width: rem-calc(24);
    }

    a {
      text-decoration: underline;
    }

    textarea {
      height: rem-calc(128);
    }

    .sib-input {
      .entry__label {
        font-size: rem-calc(14);
      }

      .entry__error {
        display: none;
      }
    }

    .sib-optin {
      margin-bottom: rem-calc(16);

      label:not(.entry_error) {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        span.checkbox {
          display: none;
        }

        p {
          margin: 0;
        }
      }
    }

    .sib-form-block {
      padding-bottom: rem-calc(16);

      .entry__specification {
        font-size: rem-calc(13);
      }
    }

    .sib-optin.sib-form-block,
    .sib-text-form-block {
      margin: 0 rem-calc(-24);
      padding: rem-calc(16) rem-calc(24);
    }

    > div:last-of-type {
      .sib-form-block {
        margin: rem-calc(-16) rem-calc(-24) rem-calc(-24);
        padding: rem-calc(16) rem-calc(24);
        text-align: right;

        button {
          display: inline-flex;
          align-items: center;
          padding: rem-calc(12) rem-calc(16);
          background-color: $color-pacific-blue-default;
          color: $color-white;
          font-weight: 600;
          border-radius: $border-radius-small;

          svg {
            height: rem-calc(16);
            width: rem-calc(16);
            margin-right: rem-calc(16);
            fill: $color-white;
          }

          svg {
            animation: spin-animation 0.5s infinite;
            display: inline-block;
          }

          .sib-hide-loader-icon {
            display: none;
          }

          @keyframes spin-animation {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(359deg);
            }
          }
        }
      }
    }
  }

  &-success {
    box-shadow: $box-shadow-medium;
    border-radius: $border-radius-small;
    border: solid $color-success-border 2px;
    overflow: hidden;
    padding: rem-calc(24);
    background-color: $color-success-background;
  }
}

@import "./functions/rem-calc";

/** Utilities */

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: rem-calc(16);
}

.mb-2 {
  margin-bottom: rem-calc(32);
}

.mb-3 {
  margin-bottom: rem-calc(48);
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.w-100 {
  width: 100%;
}

.ws-pre-line {
  white-space: pre-line;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

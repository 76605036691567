@import '../../styles/functions/rem-calc';
@import '../../styles/vars-box-shadows';
@import '../../styles/vars-colors';
@import '../../styles/vars-media-queries';

.sw-card-event-program-item {
  background-color: $color-white;
  box-shadow: $box-shadow-small;
  gap: rem-calc(16);
  padding: rem-calc(16);

  > div:first-child {
    margin-bottom: rem-calc(16);
    min-width: rem-calc(75);
  }

  @media #{$mq-min480} {
    display: flex;

    > div:first-child {
      margin-bottom: 0;
    }
  }

  .time {
    border-bottom: solid $color-alabaster-default 2px;
    padding-bottom: rem-calc(4);

    @media #{$mq-min480} {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .date,
  .time,
  .title {
    font-weight: 600;
  }

  .description {
    @media #{$mq-min480} {
      padding-left: rem-calc(16);
      border-left: solid $color-alabaster-default 2px;
    }
  }

  .strapi-markdown {
    padding-top: rem-calc(16);
  }

  &-pacific-blue {
    color: $color-black-default;
  }

  &-techbar {
    color: $color-black-default;

    .time {
      border-bottom: solid $color-pacific-blue-default 2px;
      padding-bottom: rem-calc(4);

      @media #{$mq-min480} {
        border-bottom: none;
        padding-bottom: 0;
      }
    }

    .description {
      @media #{$mq-min480} {
        border-left: solid $color-pacific-blue-default 2px;
      }
    }
  }
}

@import '../../styles/vars-colors';
@import '../../styles/vars-media-queries';

.sw-section-content-card {
  // styles
}

.sw-section-content-card-carousel {
  background-color: white;
  border-radius: 1rem;
  border: 0.3rem solid $color-pacific-blue-dark;
  margin: 1rem;
  padding: 0 !important;  

  & .content-container {
    padding: 0 !important;    
  }

  & .carousel-container {
    padding: 0 0 1.5rem 0;    
  }

  .sw-page-header-content.embedded & {
    margin: 0;
  }
}

.sw-card-carousel-content {
  display: flex;
  flex-direction: column;
  margin: 1.5rem 2.5rem 1.5rem 2.5rem;

  .header {
    align-items: center;
    color: $color-pacific-blue-dark;
    display: flex;
    flex-direction: row;
    gap: rem-calc(16);

    > div {
      align-items: center;
      display: flex;
      min-height: rem-calc(48);
    }

    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 0;
    }
  }

  .content {    
    display: flex;
    flex-direction: row;
    justify-content: space-between;    
    color: $color-pacific-blue-dark;    

    & .link-icon {
      margin-left: 2.5rem;      

      & svg {
        color: $color-pacific-blue-light;
        width: 2.5rem;
        height: 2.5rem;
      }
    }    
  }

  .footer {
    text-align: center;
  }
}
@media print {
  .sw-main-menu .menu-top-mobile,
  .sw-main-menu .menu-top-desktop,
  .sw-brand .suffix,
  .sw-page-header .social-media,
  .content-container > .grid-gap > div:first-child,
  .evaluation-wrapper .sw-button-group--fullwidth,
  footer#footer .polygon,
  #cookieConsentBox
  {
    display: none !important;
    // Any Other style you want to add
  }

  .sw-page-header .polygon {
    min-height: 15rem;
  }

  .sw-main-menu {
    position: absolute;
    -webkit-print-color-adjust:exact !important;
    print-color-adjust:exact !important;
    padding-bottom: 1.5rem;
  }

  .sw-main-menu .menu-container .menu-brand svg {
    height: 2rem;
    top: 1rem;
    position: relative;
    left: 1rem;
  }

  .sw-page-header .polygon .content-container {
    align-items: flex-end;
  }

  .sw-page-header .polygon .content-container h1, .sw-page-header .polygon .content-container .h3 {
    -webkit-print-color-adjust:exact !important;
    print-color-adjust:exact !important;
    color: white;
  }

  /*
  header .content-container, main .content-container, footer .content-container {
    padding: 0;
  }
  .sw-section-reifegrad-pacific-blue {
    padding-top: 0;
  }
  */

  main.sw-page-content {
    page-break-after: always;
    break-after: page;
  }

  main.sw-page-content .evaluation-questions {
    font-size: 14px;
  }

  main.sw-page-content .evaluation-questions br {
    display: none;
  }

  .sw-team .persons > div {
    max-width: 40%;
  }
}
@import '../../styles/functions/rem-calc';
@import '../../styles/vars';
@import '../../styles/vars-colors';
@import '../../styles/vars-media-queries';

.sw-page-header {
  position: relative;
  width: 100%;

  .social-media {
    bottom: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 10;

    > div {
      margin: 0 auto;
      max-width: $content-max-width;
      padding: 0 rem-calc(8);
      text-align: right;

      @media #{$mq-min480} {
        padding: 0 rem-calc(16);
      }
    }
  }

  .container {
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15)) drop-shadow(0px 6px 12px rgba(0, 0, 0, 0.15));
  }

  .polygon {
    background-color: $color-white;
    clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 8.74vw), 0% 100%);
    min-height: 18rem;

    .image {
      height: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%;

      &-overlay,
      &-container {
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
      }

      &-overlay {
        background-color: #000000;
        z-index: 15;
      }
    }

    .content-container {
      align-items: center;
      color: $color-white;
      display: flex;
      height: 100%;
      padding-bottom: calc(5vw);
      position: relative;
      width: 100%;

      &-absolute {
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 20;
      }

      h1 {
        line-height: 1.1;
        margin-bottom: rem-calc(8);
      }

      h1,
      .h3 {
        text-shadow: rgba(0, 0, 0, 0.15) 0 2px 4px, rgba(0, 0, 0, 0.15) 0 4px 8px, rgba(0, 0, 0, 0.15) 0 6px 12px;
      }
    }

    @media #{$mq-min640} {
      min-height: 24rem;
    }

    @media #{$mq-min1024} {
      min-height: 32rem;
    }

    @media #{$mq-min1280} {
      min-height: 40rem;
    }

    @media #{$mq-min1600} {
      min-height: 48rem;
    }
  }

  &-techbar {
    .polygon {
      clip-path: none;

      .image {
        &-overlay-techbar {
          background-image: $color-techbar-header-linear-gradient;
          mix-blend-mode: multiply;
          opacity: .8;
        }
      }

      .content-container {
        padding-bottom: 0;
        padding-top: 3rem;

        .content {
          width: 100%;

          > div {
            margin: 0 auto rem-calc(16);
            text-align: center;

            &:last-child {
              margin: 0 auto;
            }
          }

          .logo {
            max-width: 65%;
          }

          .title {
            font-family: "Raleway Light", sans-serif;
            font-size: rem-calc(20);

            @media #{$mq-min640} {
              font-size: rem-calc(24);
            }

            @media #{$mq-min1024} {
              font-size: rem-calc(28);
            }

            @media #{$mq-min1280} {
              font-size: rem-calc(32);
            }
          }
        }
      }

      @media #{$mq-min640} {
        min-height: 20rem;
      }

      @media #{$mq-min1024} {
        min-height: 28rem;
      }

      @media #{$mq-min1280} {
        min-height: 36rem;
      }

      @media #{$mq-min1600} {
        min-height: rem-calc(620);
      }
    }
  }
}


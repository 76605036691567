@import "../../styles/functions/rem-calc";
@import "../../styles/mixins/section-padding";
@import "../../styles/vars";
@import "../../styles/vars-media-queries";

.sw-section-image-split {
  .split {
    display: grid;
    grid-template-columns: 1fr;

    > div {
      @include section-padding;
      overflow: hidden;

      &:first-child {
        display: none;
      }
    }

    @media #{$mq-min600} {
      grid-template-columns: 1fr 1fr;

      > div:first-child {
        display: block;
      }
    }

    &-left {
      position: relative;
      overflow: hidden;

      .image {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;

        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .drop-shadow {
        box-shadow: 0 0 20px 5px rgba(0, 0, 0, .5);
        height: 600px;
        position: absolute;
        right: 0;
        top: 0;
        transform-origin: left top;
        transform: translate(100%, 0px) rotate(10deg);
        width: 600px;
      }
    }

    &-right {
      .content {
        max-width: $content-max-width;
        padding: 0 rem-calc(16);

        h2 {
          font-size: rem-calc(24);
        }

        p {
          font-size: rem-calc(18);
          font-weight: 600;
          margin-bottom: rem-calc(32);
        }

        @media #{$mq-min480} {
          padding: 0 rem-calc(48);
        }

        @media #{$mq-min600} {
          max-width: $content-max-width / 2;
          padding: 0 rem-calc(64);

          h2 {
            font-size: rem-calc(26);
          }

          p {
            font-size: rem-calc(20);
          }
        }
      }

      .content.collapse-top {
        padding: rem-calc(16) 0 0 0;
      }

      .content.collapse-bottom {
        padding: 0 0 rem-calc(16) 0;
      }
    }
  }
}

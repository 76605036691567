@import '../../styles/functions/rem-calc';
@import '../../styles/vars-colors';
@import '../../styles/vars-border-radii';
@import '../../styles/vars-box-shadows';

.sw-card-event-compact {
  background-color: $color-white;
  border-radius: $border-radius-medium;
  box-shadow: $box-shadow-medium;

  .details {
    display: flex;
    flex-direction: column;
    gap: rem-calc(8);
    min-height: 100%;
    padding: rem-calc(16);

    & > div {
      text-align: center;
    }

    .title {
      flex: 1 0 auto;
      font-weight: 700;
    }
  }

  &-alabaster {
    color: $color-black-default;
  }

  &-pacific-blue {
    color: $color-black-default;
  }
}

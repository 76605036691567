@import '../../styles/functions/rem-calc';
@import '../../styles/vars-colors';

.sw-form-control-wrapper {
  display: flex;
  flex-direction: column;

  .help-text {
    color: $color-grey-3;
    font-size: rem-calc(12);
  }
}

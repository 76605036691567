@import '../../styles/functions/rem-calc';

.sw-card-personnel-gallery {
  .label {
    display: flex;
    flex-direction: column;
    gap: rem-calc(8);
    padding: rem-calc(8);

    .name,
    .organisation {
      font-weight: 600;
    }

    .position,
    .organisation {
      font-size: rem-calc(14);
    }
  }
}

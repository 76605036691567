@import "functions/rem-calc";
@import "fonts-icon/style";


.icon {
  &-x1 {
    font-size: rem-calc(16);
    padding: rem-calc(4);
  }

  &-x2 {
    font-size: rem-calc(24);
    padding: rem-calc(4);
  }

  &-x3 {
    font-size: rem-calc(36);
    padding: rem-calc(6);
  }

  &-x4 {
    font-size: rem-calc(48);
    padding: rem-calc(8);
  }
}

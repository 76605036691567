@import '../../styles/functions/rem-calc';
@import '../../styles/vars-colors';
@import '../../styles/vars-border-radii';
@import '../../styles/vars-box-shadows';

.sw-card-focus {
  align-items: center;
  border-radius: $border-radius-medium;
  box-shadow: $box-shadow-medium;
  display: flex;
  justify-content: space-between;
  min-height: 100%;
  padding: rem-calc(16);

  .h6 {
    margin-bottom: 0;
  }

  &-default {
    border: solid $color-black-default 2px;
  }

  &-alabaster {
    border: solid $color-black-default 2px;
  }

  &-pacific-blue {
    border: solid $color-white 2px;
  }
}

@import '../../styles/functions/rem-calc';
@import '../../styles/vars-media-queries';

.sw-job-page-meta {
  > div {
    align-items: center;
    display: flex;
    font-size: rem-calc(12);
    margin-bottom: rem-calc(8);

    svg {
      height: rem-calc(16);
      margin-right: rem-calc(8);
      width: rem-calc(16);
    }
  }

  @media #{$mq-min640} {
    display: flex;
    justify-content: space-between;

    > div {
      margin-bottom: 0;
    }
  }
}

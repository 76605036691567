@import "./functions/rem-calc";
@import "./vars-media-queries";

@mixin mixin-grid-1fr {
  display: grid;
  grid-column-gap: rem-calc(16);
  grid-row-gap: rem-calc(16);
  grid-template-columns: minmax(0, 1fr);
}

.grid-1fr {
  @include mixin-grid-1fr;

  &.untitled {
    > div:first-child {
      display: none;
    }
  }
}

.grid-1fr-1fr2fr {
  @include mixin-grid-1fr;

  @media #{$mq-min1024} {
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  }

  &.untitled {
    > div:first-child {
      display: none;
    }

    @media #{$mq-min1024} {
      > div:first-child {
        display: unset;
      }
    }
  }
}

.grid-gap {
  grid-column-gap: rem-calc(64);
}

@mixin mixin-grid-1x1fr-2x1fr {
  @include mixin-grid-1fr;

  @media #{$mq-min640} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }}

.grid-1x1fr {
  @include mixin-grid-1fr;
}

.grid-1x1fr-2x1fr {
  @include mixin-grid-1x1fr-2x1fr;
}

.grid-1x1fr-2x1fr-3x1fr {
  @include mixin-grid-1x1fr-2x1fr;

  @media #{$mq-min1024} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.grid-1x1fr-2x1fr-4x1fr {
  @include mixin-grid-1x1fr-2x1fr;

  @media #{$mq-min1280} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.grid-1x1fr-2x1fr-3x1fr-4x1fr {
  @include mixin-grid-1x1fr-2x1fr;

  @media #{$mq-min1024} {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media #{$mq-min1280} {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@import '../functions/rem-calc';

@mixin mixin-list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
    line-height: 1.5;
  }
}

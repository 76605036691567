@import '../../styles/functions/rem-calc';
@import '../../styles/vars-media-queries';

.sw-section-job-details {
  > div {
    margin-bottom: rem-calc(32);

    @media #{$mq-min768} {
      margin-bottom: rem-calc(48);
    }

    @media #{$mq-min1280} {
      margin-bottom: rem-calc(64);
    }

    &:first-child {
      margin-bottom: rem-calc(16);
    }

    &:last-child {
      margin-bottom: 0;
    }

    .sw-job-page-meta {
      justify-content: unset;

      > div {
        margin-right: rem-calc(16);
      }
    }

    .sw-button-group {
      margin-bottom: 1rem;
    }
  }
}

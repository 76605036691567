@import '../../styles/functions/rem-calc';
@import '../../styles/vars-colors';
@import '../../styles/vars-border-radii';
@import '../../styles/vars-box-shadows';

.sw-cookie-consent-box {
  background-color: $color-pacific-blue-default;
  border-radius: $border-radius-medium;
  border: solid $color-white 2px;
  bottom: 0;
  box-shadow: $box-shadow-medium;
  color: $color-white;
  display: flex;
  flex-direction: column;
  margin: rem-calc(16);
  max-width: 20rem;
  padding: rem-calc(24);
  position: fixed;
  right: 0;
  z-index: 9999;

  > div {
    margin-bottom: rem-calc(16);
  }

  .sw-button-group {
    justify-content: space-between;
    margin-bottom: 0;
  }

  &-techbar {
    background-color: $color-orange-default;
    border: solid $color-grey-mid 2px;
  }

  & .impressum-link {
    margin: 0.5rem 0 0 0;
    font-size: small;
    width: 100%;
    text-align: center;
    padding: 0;
  }
}

@import "functions/rem-calc";
@import "./vars-media-queries";

/**
* @todo: Refactor SCSS files to use @use https://stackoverflow.com/a/61500282
*   Currently the compiler doesn't handle the @use scenario for importing the function, refactor when time permits.
*/

/** Typography */
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Raleway SemiBold", sans-serif;
  line-height: 1.25;
  margin-bottom: rem-calc(16);
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding: 0;
}

h1,
.h1 {
  font-size: rem-calc(24);

  @media #{$mq-min768} {
    font-size: rem-calc(32);
  }

  @media #{$mq-min1024} {
    font-size: rem-calc(48);
  }

  @media #{$mq-min1280} {
    font-size: rem-calc(64);
  }
}

h2,
.h2 {
  font-size: rem-calc(22);

  @media #{$mq-min768} {
    font-size: rem-calc(28);
  }

  @media #{$mq-min1024} {
    font-size: rem-calc(40);
  }

  @media #{$mq-min1280} {
    font-size: rem-calc(48);
  }
}

h3,
.h3 {
  font-size: rem-calc(24);

  @media #{$mq-min768} {
    font-size: rem-calc(28);
  }

  @media #{$mq-min1280} {
    font-size: rem-calc(40);
  }
}

h4,
.h4 {
  font-size: rem-calc(22);

  @media #{$mq-min768} {
    font-size: rem-calc(26);
  }

  @media #{$mq-min1280} {
    font-size: rem-calc(32);
  }
}

h5,
.h5 {
  font-size: rem-calc(20);

  @media #{$mq-min768} {
    font-size: rem-calc(24);
  }

  @media #{$mq-min1280} {
    font-size: rem-calc(28);
  }
}

h6,
.h6 {
  font-size: rem-calc(16);

  @media #{$mq-min1024} {
    font-size: rem-calc(20);
  }
}

p {
  margin-bottom: rem-calc(16);

  &:last-child {
    margin-bottom: 0;
  }
}

p.large {
  font-weight: 600;
  font-size: 110%;

  @media #{$mq-min768} {
    font-size: 125%;
  }
}

p.larger {
  font-weight: 600;
  font-size: 125%;

  @media #{$mq-min768} {
    font-size: 150%;
  }
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

@import '../../styles/vars-colors';

.sw-section-event-list {
  &-alabaster {
    background-color: $color-alabaster-default;
    color: $color-black-default;
  }

  &-pacific-blue {
    background-color: $color-pacific-blue-default;
    color: $color-white;

    .h5 {
      color: $color-white;
    }
  }
}

@import '../../styles/functions/rem-calc';
@import '../../styles/vars-colors';

.sw-card-event {
  .h6 {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .details {
    display: flex;
    flex-direction: column;
    gap: rem-calc(8);
    padding: rem-calc(16) rem-calc(16) 0 rem-calc(16);
  }

  .strapi-markdown {
    padding: 0 rem-calc(16) rem-calc(16) rem-calc(16);
  }

  &-alabaster {
    color: $color-black-default;
  }

  &-pacific-blue {
    color: $color-white;
  }
}

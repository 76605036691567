@import './functions/rem-calc';
@import './vars-border-radii';
@import './vars-box-shadows';
@import './vars-colors';

/** Buttons */

button {
  background-color: unset;
  color: inherit;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: 0;
}

a.btn,
button.btn {
  align-items: center;
  border-radius: $border-radius-small;
  box-shadow: $box-shadow-medium;
  display: inline-flex;
  font-weight: 600;
  height: auto;
  line-height: rem-calc(24);
  padding: rem-calc(8) rem-calc(16);
  width: 100%;
  justify-content: center;

  .link-icon {
    align-items: center;
    height: rem-calc(24);
    width: rem-calc(24);
    margin-left: rem-calc(8);
    margin-bottom: rem-calc(-5.5);
  }

  &.no-border {
    border: none;
  }

  &.no-box-shadow {
    box-shadow: none;
  }

  &-alabaster {
    background-color: $color-alabaster-default;
    border: solid 2px $color-alabaster-dark;

    &:hover {
      background-color: $color-alabaster-light;
      transition: background-color 500ms linear;
    }
  }

  &-default,
  &-cultured {
    background-color: $color-cultured-default;
    border: solid 2px $color-black-default;

    &:hover {
      background-color: $color-cultured-light;
      transition: background-color 500ms linear;
    }
  }

  &-pacific-blue {
    background-color: $color-pacific-blue-default;
    border: solid 2px $color-white;
    color: $color-white;

    &:hover {
      background-color: $color-pacific-blue-light;
      transition: background-color 500ms linear;
    }
  }

  &-pacific-blue-dark {
    background-color: $color-pacific-blue-dark;
    border: solid 2px $color-white;
    color: $color-white;

    &:hover {
      background-color: $color-pacific-blue-default;
      transition: background-color 500ms linear;
    }
  }

  &-red {
    background-color: $color-red-default;
    border: solid 2px $color-white;

    &:hover {
      background-color: $color-red-light;
      transition: background-color 500ms linear;
    }
  }

  &-orange {
    background-color: $color-orange-default;
    border: solid 2px $color-white;

    &:hover {
      background-color: $color-orange-light;
      transition: background-color 500ms linear;
    }
  }
}

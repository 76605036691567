@import './functions/rem-calc';
@import './mixins/list-unstyled';
@import './vars-colors';

/** Lists */

.list-unstyled {
  @include mixin-list-unstyled;
}

ol,
ul {
  @include mixin-list-unstyled;
  margin-bottom: rem-calc(16);

  li{
    margin-bottom: rem-calc(16);
  }
}

ol {
  list-style-type: decimal;

  li {
    margin-left: rem-calc(16);
  }
}

ul:not(.list-unstyled)
{
  li {
    font-size: rem-calc(16);
    padding-left: rem-calc(16);

    &:before {
      color: $color-pacific-blue-dark;
      content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
      display: inline-block;
      font-size: rem-calc(24);
      font-weight: bold;
      line-height: rem-calc(24);
      margin-left: rem-calc(-20);
      position: absolute;
      width: 1rem;
    }
  }
}

ol:not(.list-unstyled) {
  list-style: none;
  counter-reset: syncwork-counter;

  li {
    counter-increment: syncwork-counter;
    font-size: rem-calc(16);
    padding-left: rem-calc(4);
  }

  li::before {
    content: counter(syncwork-counter) ". ";
    color: $color-pacific-blue-dark;
    display: inline-block;
    font-weight: bold;
    line-height: rem-calc(24);
    margin-left: rem-calc(-20);
    position: absolute;
    width: 1rem;
  }
}

.sw-page {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .sw-page-content {
    flex: 1 1 auto;
  }
}

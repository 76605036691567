@import '../../styles/functions/rem-calc';
@import '../../styles/vars-colors';

.sw-card-publication {
  .overlay-component {
    background: linear-gradient(180deg, transparent 0%, rgba(0,0,0,.5) 45%);
    color: $color-white;
    padding: rem-calc(64) rem-calc(16) rem-calc(16);
    text-align: left;
    width: 100%;

    .h6 {
      margin-bottom: 0;
    }
  }
}

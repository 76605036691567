@import "../../styles/functions/rem-calc";
@import "../../styles/vars-media-queries";

@import "../CardQuote/CardQuote.mixin";

.sw-card-video-quote {
  display: grid;
  gap: rem-calc(32);
  grid-template-columns: minmax(0, 1fr);

  @media #{$mq-min1280} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@import "../../styles/functions/rem-calc";
@import "../../styles/vars-media-queries";

@mixin card-quote-mixin {
  .quote {
    &-symbol {
      height: rem-calc(32);
      opacity: .25;
    }

    &-text {
      font-size: rem-calc(20);
      font-weight: 700;
      margin-bottom: rem-calc(32);

      @media #{$mq-min600} {
        font-size: rem-calc(24);
      }
    }

    &-attribution {
      display: block;

      & > div {
        white-space: nowrap;
      }

      .forward-slash {
        display: none;
        height: rem-calc(24);
        opacity: .25;
      }

      @media #{$mq-min480} {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;

        .forward-slash {
          display: block;
        }
      }
    }
  }
}

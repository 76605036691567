@import '../../styles/functions/rem-calc';
@import '../../styles/vars-border-radii';
@import '../../styles/vars-box-shadows';
@import '../../styles/vars-colors';
@import '../../styles/vars-media-queries';

.sw-card-icon {
  display: flex;
  flex-direction: row;
  gap: rem-calc(16);

  .align-items-center {
    align-items: center;
    display: flex;

    .h6 {
      margin-bottom: 0;
    }
  }
}

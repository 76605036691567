@import "../../styles/vars-colors";

.sw-section-send-in-blue {
  &-alabaster {
    background-color: $color-alabaster-default;
    color: $color-black-default;
  }

  &-pacific-blue {
    background-color: $color-pacific-blue-default;
    color: $color-white;

    .h5 {
      color: $color-white;
    }
  }

  .send-in-blue-form {

  }
}

@import '../../styles/vars-media-queries';

.sw-page-content {
  .back-to-top-button {
    line-height: 2rem;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    background-color: #00b6c8;
    width: 50px;
    height: 50px;
    border: white;
    color: white;
    border: 1px solid rgba(255,255,255,.125);
    border-radius: 0.375rem;
    box-shadow: 3px 3px 12px -2px rgba(0, 0, 0, 0.25);
  }
}

.sw-page-header-content.embedded {
  display: none;  

  @media #{$mq-min1024} {
    display: block;
    margin-top: 2rem;
    width: 30rem;
  }

  @media #{$mq-min1280} {
    margin-top: 5rem;
    width: 40rem;
  }
}

.sw-page-header-content.not-embedded {
  margin-top: 1rem;

  @media #{$mq-min1024} {
    display: none;
  }
}
@import "../../styles/functions/rem-calc";
@import "../../styles/vars-colors";

.sw-section-video-quote {
  .sw-card-video-quote {
    margin-bottom: rem-calc(32);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-alabaster {
    background-color: $color-alabaster-default;
  }

  &-pacific-blue {
    background-color: $color-pacific-blue-default;
    color: $color-white;
  }
}

@import "../../styles/vars-colors";

.sw-section-multi-select {
  &-alabaster {
    background-color: $color-alabaster-default;
  }

  &-pacific-blue {
    background-color: $color-pacific-blue-default;
    color: $color-white;
  }
}

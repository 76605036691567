@import '../../styles/functions/rem-calc';
@import '../../styles/vars-colors';
@import '../../styles/vars-media-queries';

.sw-section-customer-card {
  &-pacific-blue {
    background-color: $color-pacific-blue-default;
    color: $color-white;
  }

  .grid-section-customer-card-2x1fr-4x1fr,
  .grid-section-customer-card-2x1fr-4x1fr-6x1fr {
    display: grid;
    grid-gap: rem-calc(16);
    gap: rem-calc(32);
  }

  .grid-section-customer-card-2x1fr-4x1fr {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media #{$mq-min1024} {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

  .grid-section-customer-card-2x1fr-4x1fr-6x1fr {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media #{$mq-min1024} {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media #{$mq-min1280} {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }
  }

}

$icomoon-font-family: "syncwork" !default;
$icomoon-font-path: "fonts" !default;

$icon-arrow-left: "\f060";
$icon-arrow-right: "\f061";
$icon-arrow-up: "\f062";
$icon-arrow-down: "\f063";
$icon-bars: "\f0c9";
$icon-chevron-up: "\f077";
$icon-chevron-down: "\f078";
$icon-facebook: "\ea90";
$icon-facebook2: "\ea91";
$icon-whatsapp: "\ea93";
$icon-twitter: "\ea96";
$icon-youtube: "\ea9d";
$icon-linkedin: "\eac9";
$icon-linkedin2: "\eaca";
$icon-xing: "\ead3";
$icon-xing2: "\ead4";

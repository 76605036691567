@import "../../styles/vars";
@import "../../styles/vars-media-queries";
@import "../../styles/functions/rem-calc";

.sw-icon-preview {
  .content-container {
    margin: 0 auto;
    max-width: $content-max-width;
    padding: 0 rem-calc(8);

    @media #{$mq-min480} {
      padding: 0 rem-calc(16);
    }
  }

  .icon-list {
    margin: 0 auto;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(6, minmax(5rem, 100%));

    > div {
      align-items: center;
      display: flex;
      justify-content: center;
      flex-direction: column;

      svg {
        height: 4rem;
        width: 4rem;
      }
    }
  }
}

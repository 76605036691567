@import '../../styles/functions/rem-calc';
@import '../../styles/vars-colors';

.sw-count-up {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .count {
      font-size: rem-calc(64);
    }

    .label {
      font-size: rem-calc(28);
    }
}

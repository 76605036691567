@import "../../styles/functions/rem-calc";
@import "../../styles/vars-border-radii";
@import "../../styles/vars-box-shadows";
@import "../../styles/vars-colors";

@import "../../styles/form-checkbox";

.sw-form-contact {
  box-shadow: $box-shadow-medium;
  border-radius: $border-radius-small;
  border: solid $color-pacific-blue-default 2px;
  overflow: hidden;
  padding: rem-calc(24);
  background-color: $color-white;

  .grid-1x1fr,
  .grid-1x1fr-2x1fr {
    gap: rem-calc(24);
  }

  .label {
    font-size: rem-calc(14);
    margin-bottom: rem-calc(4);
    display: block;

    > span {
      font-size: rem-calc(12);
    }
  }

  .form-control {
    border-radius: $border-radius-small;
    border: solid $color-grey-1 2px;
    display: block;
    font-size: rem-calc(14);
    outline: none;
    padding: rem-calc(8);
    transition: 0.5s;

    &:focus {
      border: solid $color-pacific-blue-default 2px;
    }

    &.error {
      border: solid $color-red-light 2px;
    }
  }

  .message {
    textarea {
      height: rem-calc(128);
    }
  }

  .form-footer {
    background-color: $color-cultured-light;
    margin-bottom: rem-calc(-24);
    margin-left: rem-calc(-24);
    margin-right: rem-calc(-24);
    padding: rem-calc(24);

    .info-message {
      margin-bottom: rem-calc(32);
    }

    .opt-in {
      align-items: center;
      display: flex;
      margin-bottom: rem-calc(32);

      & > div:first-child {
        margin-right: rem-calc(16);
      }

      .bot-field {
        display: none;
      }
    }

    .buttons {
      text-align: right;
    }
  }
}

.sw-form-contact-success {
  box-shadow: $box-shadow-medium;
  border-radius: $border-radius-small;
  border: solid $color-success-border 2px;
  overflow: hidden;
  padding: rem-calc(24);
  background-color: $color-success-background;
}

@import '../../styles/functions/rem-calc';
@import '../../styles/vars-colors';

.sw-self-check-segment {
  .questions {
    margin-bottom: rem-calc(16);
  }

  &.collapsed {
    height: 0;
    overflow: hidden;
  }

  .sw-button-group {
    justify-content: space-between;
    width: 100%;
  }
}

.sw-self-check-segment-use-scale-answers {
  .questions {
    margin-bottom: 0;
  }

  .sw-self-check-question .radio-button-group {
    align-items: center;
  }
  .sw-form-control-radio-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 50px;
    width: 35px;
    background-color: #de343b;
  }

  .sw-form-control-radio-button > label {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .sw-form-control-radio-button--1 {
    background-color: #ee7b3b;
  }

  .sw-form-control-radio-button--2 {
    background-color: #f2c026;
  }

  .sw-form-control-radio-button--3 {
    background-color: #e7e239;
  }

  .sw-form-control-radio-button--4 {
    background-color: #a4db43;
  }

  .sw-form-control-radio-button--5 {
    background-color: #42be54;
  }
          
  .sw-form-control-radio-button input[type=radio] {
    display: block;
    margin-right: 0;
  }
          
  .sw-form-control-radio-button input[type=radio]:checked:after {
    content: '';
    display: block;
    border: 3px solid black;
    position: absolute;
    inset: -4px;
    border-radius: 4px;
  }


  .svg-container {
    display: block;
    margin-top: 50px;
  }

}
@import "../../styles/vars-colors";
@import "../../styles/vars-media-queries";

.menu-top-mobile {
  .language-switch {
    margin: 1rem;
    border: solid 2px #fff;
    border-radius: 0.375rem;
    box-shadow: 8px 8px 8px -8px rgba(0, 0, 0, 0.2);
    background-color: #00b6c8;
    position: sticky;
    bottom: 0;
    float: right;
  }
}

.language-switch-link {
  color: rgba(255,255,255,.75);
  strong {
    color: white;
  }
}

@media #{$mq-max1152} {
  /* extra controls are in mega menu */
  .extra-controls {
    .language-switch {
      margin: 1rem;
      position: fixed;
      bottom: 0;
      right: 0;

      .language-switch-link {
        display: inline-block;
        line-height: 3rem;
        padding: 0 0.75rem;
        border: solid 2px #fff;
        border-radius: 0.375rem;
        box-shadow: 8px 8px 8px -8px rgba(0, 0, 0, 0.2);
        background-color: #00b6c8;
      }
    }
  }
}
@import "./functions/rem-calc";
@import "./vars-colors";
@import "./vars-media-queries";

.section {
  &-alabaster {
    background-color: $color-alabaster-default;
    color: $color-black-default;
  }

  &-cultured {
    background-color: $color-cultured-default;
    color: $color-black-default;
  }

  &-pacific-blue {
    background-color: $color-pacific-blue-default;
    color: $color-white;
  }
}

@import '../../styles/functions/rem-calc';
@import '../../styles/vars-colors';
@import '../../styles/vars-border-radii';
@import '../../styles/vars-box-shadows';

.sw-button-icon {
  a,
  span {
    background-color: $color-white;
    border-radius: $border-radius-small;
    box-shadow: $box-shadow-medium;
    color: $color-pacific-blue-default;
    display: block;
    line-height: 1;
    padding: rem-calc(8);
  }
}

@import "../functions/rem-calc";
@import "../vars-media-queries";

@mixin section-padding {
  padding-bottom: rem-calc(48);
  padding-top: rem-calc(48);
  position: relative;

  @media #{$mq-min1024} {
    padding-bottom: rem-calc(64);
    padding-top: rem-calc(64);
  }

  &.collapse-top {
    padding-top: rem-calc(16);
  }

  &.collapse-bottom {
    padding-bottom: rem-calc(16);
  }
}

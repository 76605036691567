@import '../../styles/functions/rem-calc';

.sw-button-cta {
  display: inline-block;
  width: fit-content;

  .btn {
    font-size: rem-calc(20);
    line-height: unset;
    padding: rem-calc(12) rem-calc(24);

    .link-icon {
      height: rem-calc(32);
      width: rem-calc(32);
      text-align: end;
    }
  }
}

@import '../../styles/functions/rem-calc';
@import '../../styles/vars-media-queries';
@import '../../styles/vars-colors';

.sw-section-news-item-page-details {
  > div {
    margin-bottom: rem-calc(32);

    @media #{$mq-min768} {
      margin-bottom: rem-calc(48);
    }

    @media #{$mq-min1280} {
      margin-bottom: rem-calc(64);
    }

    &:first-child {
      margin-bottom: rem-calc(16);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .strapi-markdown {
    color: #000000;
  }

  .teaser,
  .related-links {
    color: #000000;
    font-weight: 600;
  }

  .related-links {
    color: $color-black-default;
    font-size: rem-calc(24);
  }
}

/** @todo: remove other font faces and associated files if left unused */

/*
@font-face {
  font-family: "Raleway Medium";
  src: url("fonts/Raleway-Medium.eot");
  src: local("Raleway Medium"), local("Raleway-Medium"),
  url("fonts/Raleway-Medium.eot?#iefix") format("embedded-opentype"),
  url("fonts/Raleway-Medium.woff2") format("woff2"),
  url("fonts/Raleway-Medium.woff") format("woff"),
  url("fonts/Raleway-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
*/

@font-face {
  font-family: "Raleway SemiBold";
  src: url("fonts/Raleway-SemiBold.eot");
  src:
    local("Raleway SemiBold"),
    local("Raleway-SemiBold"),
    url("fonts/Raleway-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("fonts/Raleway-SemiBold.woff2") format("woff2"),
    url("fonts/Raleway-SemiBold.woff") format("woff"),
    url("fonts/Raleway-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/*
@font-face {
  font-family: "Raleway Italic";
  src: url("fonts/Raleway-Italic.eot");
  src: local("Raleway Italic"), local("Raleway-Italic"),
  url("fonts/Raleway-Italic.eot?#iefix") format("embedded-opentype"),
  url("fonts/Raleway-Italic.woff2") format("woff2"),
  url("fonts/Raleway-Italic.woff") format("woff"),
  url("fonts/Raleway-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
*/

/*
@font-face {
  font-family: "Raleway Bold";
  src: url("fonts/Raleway-Bold.eot");
  src: local("Raleway Bold"), local("Raleway-Bold"),
  url("fonts/Raleway-Bold.eot?#iefix") format("embedded-opentype"),
  url("fonts/Raleway-Bold.woff2") format("woff2"),
  url("fonts/Raleway-Bold.woff") format("woff"),
  url("fonts/Raleway-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
*/

@font-face {
  font-family: "Raleway Regular";
  src: url("fonts/Raleway-Regular.eot");
  src:
    local("Raleway Regular"),
    local("Raleway-Regular"),
    url("fonts/Raleway-Regular.eot?#iefix") format("embedded-opentype"),
    url("fonts/Raleway-Regular.woff2") format("woff2"),
    url("fonts/Raleway-Regular.woff") format("woff"),
    url("fonts/Raleway-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway Light";
  src: url("fonts/Raleway-Light.eot");
  src: local("Raleway Light"), local("Raleway-Light"),
  url("fonts/Raleway-Light.eot?#iefix") format("embedded-opentype"),
  url("fonts/Raleway-Light.woff2") format("woff2"),
  url("fonts/Raleway-Light.woff") format("woff"),
  url("fonts/Raleway-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@import "./vars-colors";

.color-alabaster-dark {
  color: $color-alabaster-dark;
}

.color-alabaster-default {
  color: $color-alabaster-default;
}

.color-alabaster-light {
  color: $color-alabaster-light;
}

.color-cultured-dark {
  color: $color-cultured-dark;
}

.color-cultured-default {
  color: $color-cultured-default;
}

.color-cultured-light {
  color: $color-cultured-light;
}

.color-black {
  color: $color-black-default;
}

.color-white {
  color: $color-white;
}

.color-gunmetal-dark {
  color: $color-gunmetal-dark;
}

.color-gunmetal-default {
  color: $color-gunmetal-default;
}

.color-gunmetal-light {
  color: $color-gunmetal-light;
}

.color-pacific-blue-dark {
  color: $color-pacific-blue-dark;
}

.color-pacific-blue-default {
  color: $color-pacific-blue-default;
}

.color-pacific-blue-light {
  color: $color-pacific-blue-light;
}

.color-red-dark {
  color: $color-red-dark;
}

.color-red-default {
  color: $color-red-default;
}

.color-red-light {
  color: $color-red-light;
}


/* Background Colors */

.color-bg-alabaster-dark {
  background-color: $color-alabaster-dark;
}

.color-bg-alabaster-default {
  background-color: $color-alabaster-default;
}

.color-bg-alabaster-light {
  background-color: $color-alabaster-light;
}

.color-bg-cultured-dark {
  background-color: $color-cultured-dark;
}

.color-bg-cultured-default {
  background-color: $color-cultured-default;
}

.color-bg-cultured-light {
  background-color: $color-cultured-light;
}

.color-bg-black {
  background-color: $color-black-default;
}

.color-bg-white {
  background-color: $color-white;
}

.color-bg-gunmetal-dark {
  background-color: $color-gunmetal-dark;
}

.color-bg-gunmetal-default {
  background-color: $color-gunmetal-default;
}

.color-bg-gunmetal-light {
  background-color: $color-gunmetal-light;
}

.color-bg-pacific-blue-dark {
  background-color: $color-pacific-blue-dark;
}

.color-bg-pacific-blue-default {
  background-color: $color-pacific-blue-default;
}

.color-bg-pacific-blue-light {
  background-color: $color-pacific-blue-light;
}

.color-bg-red-dark {
  background-color: $color-red-dark;
}

.color-bg-red-default {
  background-color: $color-red-default;
}

.color-bg-red-light {
  background-color: $color-red-light;
}

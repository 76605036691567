@import "../../styles/vars-colors";

.sw-section-quote-carousel {
  &-alabaster {
    background-color: $color-alabaster-default;
  }

  &-pacific-blue {
    background-color: $color-pacific-blue-default;
    color: $color-white;
  }

  &-techbar {
    background-image: $color-techbar-section-linear-gradient-2;
    background-position: center center;
    color: $color-white;
  }
}

@import "../../styles/functions/rem-calc";
@import "../../styles/vars-colors";
@import "../../styles/vars-media-queries";

.sw-section-personnel-gallery {
  .grid-personnel-gallery {
    display: grid;
    grid-gap: rem-calc(16);
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media #{$mq-min480} {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media #{$mq-min960} {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media #{$mq-min1024} {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media #{$mq-min1280} {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
  }

  &-default {

  }

  &-techbar {
    background-image: $color-techbar-section-linear-gradient-1;
    background-position: center center;
    color: $color-white;
  }

  &-alabaster {
    background-color: $color-alabaster-default;
  }

  &-pacific-blue {
    background-color: $color-pacific-blue-default;
    color: $color-white;
  }
}

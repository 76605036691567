@import '../../styles/functions/rem-calc';
@import '../../styles/vars-colors';
@import '../../styles/vars-border-radii';

.sw-youtube-video {
  position: relative;

  .consent-icon,
  .consent-modal {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .consent-modal {
    background-color: $color-cultured-default;
    border-radius: $border-radius-medium;
    color: $color-black-default;
    padding: rem-calc(16);
    text-align: center;

    .sw-button-group {
      display: flex;
      justify-content: center;

      .btn {
        flex: 1 0 50%;
      }
    }
  }

  .video-modal {
    border: solid $color-white 4px;
    border-radius: $border-radius-medium;
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 20;

    &-opacity {
      opacity: 1;
    }
  }
}


@import '../../styles/functions/rem-calc';
@import '../../styles/vars-colors';

.carousel {
  &-container {
    overflow: hidden;
    padding-bottom: rem-calc(32);
    position: relative;
  }

  &-indicators {
    bottom: rem-calc(4);
    display: flex;
    justify-content: center;
    left: 0;
    list-style: none;
    margin: 0 auto 0 auto;
    padding-left: 0;
    position: absolute;
    right: 0;
    z-index: 10;

    li::before {
      display: none !important;
    }
  }

  &-indicator {
    border-radius: 50%;
    border: solid $color-pacific-blue-default 2px;
    cursor: pointer;
    flex: 0 0 auto;
    height: rem-calc(16);
    margin-left: rem-calc(16);
    margin-right: rem-calc(16);
    opacity: .8;
    position: relative;
    width: rem-calc(16);

    &:hover {
      border-color: $color-pacific-blue-dark;
    }

    &.active {
      background-color: rgba(51, 197, 211, .3);
    }

    &-alabaster {
      border: solid $color-black-default 2px;

      &.active {
        background-color: rgba(34, 41, 47, .3);
      }
    }

    &-pacific-blue,
    &-techbar {
      border: solid $color-white 2px;

      &:hover {
        border-color: $color-white;
      }

      &.active {
        background-color: rgba(255, 255, 255, .3);
      }
    }
  }

  &-track {
    align-items: stretch;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    position: relative;
  }
}

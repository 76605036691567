@import "../../styles/vars-colors";

.sw-section-counter {
  &-default {
    .sw-count-up {
      .count {
        color: $color-pacific-blue-default;
      }
    }
  }

  &-alabaster {
    background-color: $color-alabaster-default;

    .sw-count-up {
      .count {
        color: $color-pacific-blue-default;
      }
    }
  }

  &-pacific-blue {
    background-color: $color-pacific-blue-default;
    color: $color-white;

    .sw-count-up {
      .count {
        color: $color-white;
      }
    }
  }
}

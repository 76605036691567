@import "../../styles/mixins/section-padding";
@import "../../styles/vars-colors";

.sw-section-image-banner {
  @include section-padding;
  position: relative;

  .image {
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;

    &-overlay,
    &-container {
      bottom: 0;
      left: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
    }

    &-overlay-techbar {
      background-image: $color-techbar-header-linear-gradient;
      mix-blend-mode: multiply;
      opacity: .8;
    }

    &-overlay {
      background-color: #000;
      z-index: 15;
    }
  }

  .banner {
    position: relative;
    color: $color-white;
    width: 100%;
    z-index: 20;

    .content-container {
      display: flex;
      justify-content: flex-end;

      .content {
        flex: 0 0 33.33%;
        padding: rem-calc(32) 0;
      }
    }
  }

  .strapi-markdown {
    text-align: center;

    * {
      color: $color-white;
    }

    h1 {
      font-size: rem-calc(48);
    }

    h2 {
      font-size: rem-calc(32);
    }

    h3 {
      font-size: rem-calc(24);
    }

    h4 {
      font-size: rem-calc(20);
    }

    h5 {
      font-size: rem-calc(18);
    }

    h6 {
      font-size: rem-calc(16);
    }
  }
}

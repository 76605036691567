@import '../../styles/functions/rem-calc';

.sw-button-group {
  display: inline-flex;
  gap: rem-calc(8);
  justify-content: center;

  .btn {
    width: unset;
  }
}

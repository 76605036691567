@import '../../styles/functions/rem-calc';
@import '../../styles/vars-border-radii';
@import '../../styles/vars-colors';
@import '../../styles/vars-box-shadows';

.sw-image {
  .aspect-ratio {
    overflow: hidden;
    position: relative;
    width: 100%;

    .overlay {
      align-items: flex-end;
      bottom: 0;
      display: flex;
      left: 0;
      position: absolute;
      justify-content: left;
      right: 0;
      top: 0;
      z-index: 100;
    }

    &-inside,
    &-object {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &-inside {
      background-position: center;
      background-size: cover;

    }

    &-object {
      img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
      }
    }
  }

  &-elevated {
    .aspect-ratio {
      border-radius: $border-radius-medium;
      border: solid $color-white rem-calc(4);
      box-shadow: $box-shadow-medium;

      &-inside {
        border-radius: $border-radius-small;
      }

      &-object {
        img {
          border-radius: $border-radius-small;
        }
      }
    }
  }
}

/* Colors */
$color-alabaster-dark: #b8b8b2;
$color-alabaster-default: #e6e6df;
$color-alabaster-light: #ebebe5;
$color-cultured-dark: #c2c3c5;
$color-cultured-default: #f3f4f6;
$color-cultured-light: #f5f6f8;
$color-gunmetal-dark: #1b2126;
$color-gunmetal-default: #22292f;
$color-gunmetal-light: #4e5459;
$color-pacific-blue-dark: #0092a0;
$color-pacific-blue-default: #00b6c8;
$color-pacific-blue-light: #33c5d3;
$color-red-dark: #cc0a00;
$color-red-default: #ff0d00;
$color-red-light: #ff3d33;

$color-orange-dark: #de8104;
$color-orange-default: #f79a1d;
$color-orange-light: #ffb437;

$color-black-default: $color-gunmetal-default;
$color-black: #000;
$color-white: #fff;
$color-grey-mid: #646464;
$color-grey-1: #eee;
$color-grey-2: #ccc;
$color-grey-3: #aaa;
$color-grey-4: #888;

$color-success-border: rgba(0, 128, 0, 1);
$color-success-background: rgba(167, 243, 208, .2);

$color-techbar-header-linear-gradient: linear-gradient(90deg, rgb(0, 176, 225), rgb(239, 191, 30), rgb(247, 153, 28));
$color-techbar-section-linear-gradient-1: linear-gradient(90deg, rgb(0, 176, 225), rgb(57, 189, 176));
$color-techbar-section-linear-gradient-2: linear-gradient(90deg, rgb(57, 189, 176), rgb(115, 202, 127));

@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?pe6dgg') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?pe6dgg') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?pe6dgg##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-bars {
  &:before {
    content: $icon-bars;
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up;
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-facebook2 {
  &:before {
    content: $icon-facebook2;
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}
.icon-linkedin2 {
  &:before {
    content: $icon-linkedin2;
  }
}
.icon-xing {
  &:before {
    content: $icon-xing;
  }
}
.icon-xing2 {
  &:before {
    content: $icon-xing2;
  }
}


@import '../../styles/functions/rem-calc';
@import '../../styles/vars-border-radii';
@import '../../styles/vars-box-shadows';
@import '../../styles/vars-colors';
@import '../../styles/vars-media-queries';

.sw-widget-locations {
  position: relative;
  background-color: transparent;

  .image-container {
    display: none;
    position: absolute;
    width: 100%;
    z-index: 0;

    @media #{$mq-min769} {
      display: block;
    }
  }

  .locations-container {
    margin: 0 auto;
    position: relative;
    top: 50%;
    width: 100%;
    z-index: 100;

    @media #{$mq-min769} {
      width: 90%;
    }

    @media #{$mq-min960} {
      width: 85%;
    }

    @media #{$mq-min1024} {
      width: 80%;
    }

    .contact-card {
      background-color: $color-white;
      border-radius: $border-radius-medium;
      box-shadow: $box-shadow-medium;
      margin: 0;
      padding: rem-calc(16);

      @media #{$mq-min769} {
        margin: 5vw 0;
      }

      @media #{$mq-min1024} {
        margin: 7vw 0;
      }

      @media #{$mq-min1440} {
        margin: 10vw 0;
      }

      @media #{$mq-min1600} {
        margin: rem-calc(160) 0;
      }
    }

    .location-cards {
      .aspect-ratio {
        border-top-left-radius: $border-radius-medium;
        border-top-right-radius: $border-radius-medium;
      }
    }
  }
}

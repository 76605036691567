@import "../../styles/functions/rem-calc";
@import "../../styles/vars-media-queries";

.sw-team {
  header {
    margin-bottom: rem-calc(16);
  }

  .team-heading {
    font-size: rem-calc(26);
    font-weight: 600;
  }

  .persons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: rem-calc(16);

    > div {
      flex: 0 0 100%;
      max-width: rem-calc(360);

      @media #{$mq-min480} {
        max-width: rem-calc(320);
      }
    }
  }
}

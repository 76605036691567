@import "../../styles/functions/rem-calc";
@import "../../styles/vars-media-queries";

.sw-card-image-text {
  display: grid;
  gap: rem-calc(32);
  grid-template-columns: minmax(0, 1fr);

  @media #{$mq-min1440} {
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  }

  .image {
    max-width: rem-calc(360);
  }
}

@import "vars";
@import "vars-media-queries";
@import "functions/rem-calc";

/** Containers */

header,
main,
footer {
  .content-container {
    margin: 0 auto;
    max-width: $content-max-width;
    padding: 0 rem-calc(8);

    @media #{$mq-min480} {
      padding: 0 rem-calc(16);
    }
  }
}

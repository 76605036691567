@import '../../styles/functions/rem-calc';
@import '../../styles/vars-border-radii';
@import '../../styles/vars-box-shadows';
@import '../../styles/vars-colors';
@import '../../styles/vars-media-queries';

.sw-card-content {
  background-color: $color-white;
  border-radius: $border-radius-medium;
  box-shadow: $box-shadow-medium;
  display: flex;
  flex-direction: column;
  gap: rem-calc(16);
  padding: rem-calc(16);

  @media #{$mq-min768} {
    padding: rem-calc(24);
  }

  .header {
    align-items: center;
    color: $color-pacific-blue-dark;
    display: flex;
    flex-direction: row;
    gap: rem-calc(16);

    > div {
      align-items: center;
      display: flex;
      min-height: rem-calc(48);
    }

    h1, h2, h3, h4, h5, h6 {
      margin-bottom: 0;
    }
  }

  .content {
    flex: 1 0 auto;
  }

  img {
    max-width: 100%;
  }

  .footer {
    text-align: center;
  }
}

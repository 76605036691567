@import "../../styles/hamburgers/hamburgers";
@import "../../styles/functions/rem-calc";
@import "../../styles/vars";
@import "../../styles/vars-colors";
@import "../../styles/vars-media-queries";

.sw-main-menu {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;

  &:hover,
  &-active {
    background-color: rgba(0, 0, 0, 0.8);
  }

  a,
  button,
  .toggle {
    color: $color-white;
    line-height: rem-calc(56);
    padding: 0 rem-calc(12);
    text-decoration: none;

    i {
      margin-left: rem-calc(8);
    }
  }

  .menu-container {
    color: $color-white;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
    max-width: $content-max-width;

    .menu-new-indicator {
      display: flex;
      align-items: center;
      h4 {
        margin: 0;
        color: green;
      }
    }

    .menu-brand {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      min-height: rem-calc(56);
      margin-right: auto;

      a {
        display: inline-flex;
        line-height: unset;
        white-space: nowrap;
      }

      .sw-brand .suffix div {
        margin-left: 0.5rem;
        padding-left: 0.5rem;
      }

      .suffix {
        color: $color-white;
        display: none;

        @media #{$mq-max1152} {
          display: block;
        }

        > div {
          align-items: center;
          display: inline-flex;
          // padding-left: rem-calc(12);
          border-left: solid $color-white 2px;
        }
      }
    }
  }

  .menu-top-mobile {
    display: none;

    @media #{$mq-max1152} {
      display: block;
    }

    .toggle {
      font-size: rem-calc(24);

      button {
        line-height: unset;
      }
    }

    h5 {
      margin: 2rem 1rem 1rem;
    }

    .menu-flyout {
      background-color: rgba(0, 0, 0, 0.9);
      height: 100vh;
      max-width: rem-calc(360);
      overflow: auto;
      padding-top: rem-calc(56);
      position: absolute;
      right: -100vw;
      top: 0;
      width: 100vw; /* rem-calc(360); */
      z-index: -50;
      max-width: 100%;

      &.is-active {
        right: 0;
        padding-bottom: 2rem;
      }

      a,
      button,
      .toggle {
        color: $color-white;
        display: block;
        font-size: rem-calc(16);
        line-height: rem-calc(48);
        padding: 0 rem-calc(16);
        text-align: left;
        text-decoration: none;
        width: 100%;
        width: -webkit-fill-available;
      }

      .menu-sub {
        /* background-color: rgba(0, 0, 0, .2); */
        width: 100%;

        .drop {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.15s ease-out;
          interpolate-size: allow-keywords; /* for example: "fit-content" */

          &-active {
            max-height: fit-content;
            transition: max-height 0.25s ease-in;
          }

          a {
            display: block;
            padding: 0 rem-calc(16) 0 rem-calc(32);
          }
        }

        a:hover {
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .menu-top-mobile .menu-topic-area-mobile {
    // todo: marex
    background: rgba(52, 72, 89, 0.8);
    padding: 1rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    .toggle,
    a {
      color: $color-pacific-blue-default !important;
    }

    button.toggle {
      margin-left: 85px;
      border-left: solid $color-white 2px;
      width: calc(100% - 85px);
    }

    .menu-sub {
      position: relative;
    }
  }

  .menu-top-desktop {
    margin-left: auto;
  }
  .menu-topic-area-desktop,
  .menu-top-desktop {
    display: flex;

    @media #{$mq-max1152} {
      display: none;
    }

    .menu-sub {
      background-color: rgba(0, 0, 0, 0.5);
      left: 0;
      position: absolute;
      width: 100%;

      .drop {
        margin: 0 auto;
        max-width: $content-max-width;
        text-align: right;

        max-height: 0;
        overflow: hidden;
        transition: max-height 0.15s ease-out;

        &-active {
          max-height: 1000px;
          transition: max-height 0.25s ease-in;
        }

        a {
          padding: rem-calc(16);
        }
      }
    }

    .menu-structured {
      position: relative;
      .toggle.is-active {
        background: rgba(52, 72, 89, 0.8);
      }
      .menu-sub {
        background: transparent;
        &::before {
          content: "";
          display: block;
          background-color: rgba(0, 0, 0, 0.8);
          backdrop-filter: blur(10px);
          position: absolute;
          top: 0;
          left: -100vw;
          right: -100vw;
          bottom: 0;
          z-index: -1;
        }
      }
      .drop li::before {
        content: "";
        display: none;
      }
      .drop-structured {
        width: max-content;
        max-height: 100vh;
        overflow: auto;
        background: rgba(52, 72, 89, 0.125);
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        padding: .25rem 0 0 0;

        ul {
          margin: 0;
        }
        ul.parent {
          > li {
            padding: .5rem 2rem .5rem 2.5rem;
            &:has(a:hover) {
              /* Backdrop */
              background-color: rgba(0, 0, 0, 0.5);

            }
          }
        }
        a {
          line-height: unset;
          padding: 0;
          &:hover{
            background: rgba(52, 72, 89, 1);

          }
          &:has(+ ul a:hover) {
            background: rgba(52, 72, 89, 1);
          }
        }

        & ul ul li:has(a:hover) {
          background: rgba(52, 72, 89, 1);
          background: (0,0,0, 0.5);
        }

        ul li {
          display: flex;
          align-items: flex-start;
          margin: 0;
          padding-left: 0;
          position: relative;
          a {
            padding: .375rem .5rem .125rem .5rem;
          }
          &::before {
            pointer-events: none;
            content: "";
            line-height: 2rem;
            margin-left: -.2rem;
            color: white;
            font-size: 1rem;
            font-weight: normal;
          }
        }
      }
    }
  }

  .menu-topic-area-desktop {
    // todo: marex
    margin-right: auto;

    .toggle {
      align-items: center;
      display: flex;
      margin-left: 0;
      padding-left: calc(0.75rem + 1px);
      position: relative;

      &::before {
        border-left: solid $color-white 2px;
        display: block;
        content: "";
        position: absolute;
        left: 0;
        top: 1rem;
        height: 24px;
      }

      i {
        margin-top: -3px;
      }
    }

    .menu-sub .drop {
      text-align: left;
      position: relative;
      left: 85px;
      padding-right: 85px;
      border-left: 2px solid #fff;
    }
  }

  .search-button {
    display: flex;
    align-items: center;
  }
}

.secondary-menu-home-hint {
  display: none;
}

@media #{$mq-max1152} {
  .secondary-menu-home-hint {
    display: inline-block;
  }
  .onlyVisibleForDesktop {
    display: none;
  }
}

.onlyVisibleForDesktop {
  position: relative;
  padding: 1rem .5rem;
  &::before {
    border-left: solid $color-white 2px;
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 1rem;
    height: 24px;
  }
}
/*
body:not(.neueNavigation) .search-button {
  display: none !important;
}
*/
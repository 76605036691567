.backgroundGreen {
  background-color: green;
}


.maturity-svg {
  background: #d0ffff;
  aspect-ratio: 1;
  font-family: "Raleway Regular",sans-serif;

  .polygon {
    fill: none;
    stroke-linejoin: round;
    stroke-width: 1.5;
  }

  .polygon.gray {
    stroke: #00008b33;
  }

  .polygon.scale5 {
    stroke: #42be54;
  }

  .polygon.scale4 {
    stroke: #a4db43;
  }

  .polygon.scale3 {
    stroke: #e7e239;
  }

  .polygon.scale2 {
    stroke: #f2c026;
  }

  .polygon.scale1 {
    stroke: #ee7b3b;
  }

  .polygon.darkorange {
    stroke: darkorange;
    stroke-width: 2.5;
    filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.35));
    fill:rgba(255, 140, 0, 0.05)
  }

  .background {
    stroke-width: .5;
    stroke: lightblue;
  }

  .background.organisation {
    fill:rgb(217, 217, 205)
  }

  .background.technology {
    fill:rgb(200, 200, 180)
  }

  .background.data {
    fill:rgb(175, 175, 150)
  }

  .background.process {
    fill:rgb(150, 150, 130)
  }
}

@media (min-width: 1024px) { 
  .maturity-svg {
    width: 75%;
  }
}
@import '../../styles/functions/rem-calc';
@import '../../styles/vars-border-radii';
@import '../../styles/vars-box-shadows';
@import '../../styles/vars-colors';
@import '../../styles/vars-media-queries';

.sw-card-icon-caption {
  border-radius: $border-radius-medium;
  box-shadow: $box-shadow-extra-large;
  display: flex;
  flex-direction: column;
  padding: rem-calc(16);

  > div {
    text-align: center;
  }

  .caption {
    font-weight: 600;
  }
}

@import '../../styles/functions/rem-calc';
@import '../../styles/vars-colors';
@import '../../styles/vars-border-radii';
@import '../../styles/vars-box-shadows';

.sw-card-location {
  border-radius: $border-radius-medium;
  box-shadow: $box-shadow-medium;

  .sw-image {
    .aspect-ratio-inside {
      border-top-left-radius: $border-radius-medium;
      border-top-right-radius: $border-radius-medium;
    }
  }


  .overlay-component {
    background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, .7) 100%);
    color: $color-white;
    font-size: 200%;
    font-weight: 700;
    line-height: 1;
    padding: rem-calc(16);
    text-align: left;
    width: 100%;
  }

  .location-details {
    background-color: $color-white;
    padding: rem-calc(16);
    border-bottom-left-radius: $border-radius-medium;
    border-bottom-right-radius: $border-radius-medium;
  }
}

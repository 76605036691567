@import "../../styles/functions/rem-calc";
@import "../../styles/vars-colors";
@import "../../styles/vars-media-queries";

.sw-page-footer {

  .polygon {
    background-color: $color-gunmetal-default;
    clip-path: polygon(0% calc(0% + 8.74vw), 100% 0%, 100% 100%, 0% 100%);
    color: $color-white;
    padding-top: 8.74vw;
    z-index: 1000;
    position: relative;
  }

  .above-legal {
    min-height: rem-calc(215);

    @media #{$mq-min1024} {
      display: flex;
      flex-direction: row;
      background-color: transparent;
      justify-content: space-between;
    }
  }

  .commendation {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    padding: rem-calc(30) 0;

    @media #{$mq-min600} {
      justify-content: flex-end;
      padding: rem-calc(50) 0;
    }

    @media #{$mq-min1024} {
      display: block;
      position: relative;
      width: rem-calc(400);
    }

    & > div {
      display: flex;
      flex-direction: column;
      background-color: transparent;

      @media #{$mq-min1024} {
        position: absolute;
        right: 0;
        top: rem-calc(-100);
      }

      @media #{$mq-min1280} {
        top: rem-calc(-120);
      }
    }

    & .top-company {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      & img {
        width: rem-calc(130);
        padding-bottom: rem-calc(20);
      }

      & div {
        display: flex;
        justify-content: center;
        align-items: center;

        & span {
          margin: 0 0 rem-calc(40) rem-calc(10);
          font-size: smaller;
          font-weight: bold;
        }
      }
    }

    & .iso {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: rem-calc(5);

      & img {
        /*width: rem-calc(130);*/
      }

      & .ecovadis {
        margin-right: rem-calc(30);
        @media #{$mq-min600} {
          margin-left: rem-calc(-143px);
        }
      }
    }
  }

  .content-container {
    &-footer {
      padding-bottom: rem-calc(16);
      padding-top: rem-calc(32);
    }

    .logos,
    .menu {
      margin-bottom: rem-calc(16);
    }

    .logos {
      display: flex;
      justify-content: space-between;
    }

    .menu,
    .legal {
      a {
        color: $color-white;
      }
    }

    .menu {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: rem-calc(16);

      > div {
        min-width: rem-calc(160);
      }

      ul li a {
        font-size: rem-calc(12);
        line-height: rem-calc(16);
      }
    }

    .legal {
      font-size: rem-calc(12);
      line-height: rem-calc(16);

      > div {
        margin-bottom: rem-calc(16);
      }

      @media #{$mq-min600} {
        display: flex;
        justify-content: space-between;

        > div {
          margin-bottom: 0;
        }
      }

      &-links {
        display: flex;
        gap: rem-calc(16);

        a {
          font-weight: 700;
        }
      }
    }
  }

  &-techbar {
    .polygon {
      background-color: $color-black;
      clip-path: none;
    }

    .legal {
      padding: rem-calc(32) 0;
    }
  }
}
